import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, A11y } from "swiper/modules";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../state/store";
import Loader from "../loader/loader";
import styles from "./home.module.css";
import "./swiper.css";
import HistoryCard from "./historyCard/historyCard";
import Navbar from "../../components/navbar/navbar";
import Footer from "../../components/footer/footer";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { fetchHomeDetails } from "../../api/homeAPI";
import { ReactComponent as ArrowRight } from "../../assets/arrow-right-vector.svg";

const Home: React.FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { status, error } = useSelector((state: RootState) => state.home);

  const [previousLanguage, setPreviousLanguage] = useState("init");

  const [numberOfSlides, setNumberOfSlides] = useState(0);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [data, setData] = useState<any>(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetchHomeDetails(i18n.language); // Call fetchUserDetails API
        setData(response.data);
      } catch (err) {
        console.error("Failed to fetch user data");
      }
    };

    getData();

    setPreviousLanguage(i18n.language);

    const handleResize = () => {
      let slides = 0;
      if (window.innerWidth < 580) {
        slides = 1;
      } else if (window.innerWidth < 768) {
        slides = 1.5;
      } else if (window.innerWidth < 950) {
        slides = 2;
      } else if (window.innerWidth < 1080) {
        slides = 2.5;
      } else if (window.innerWidth < 1300) {
        slides = 3;
      } else {
        slides = 3.5;
      }
      setNumberOfSlides(slides);
      setScreenWidth(window.innerWidth);
    };

    let slides = 0;
    if (window.innerWidth < 580) {
      slides = 1;
    } else if (window.innerWidth < 768) {
      slides = 1.5;
    } else if (window.innerWidth < 950) {
      slides = 2;
    } else if (window.innerWidth < 1080) {
      slides = 2.5;
    } else if (window.innerWidth < 1300) {
      slides = 3;
    } else {
      slides = 3.5;
    }

    setNumberOfSlides(slides);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetchHomeDetails(i18n.language); // Call fetchUserDetails API
        setData(response.data);
      } catch (err) {
        console.error("Failed to fetch user data");
      }
    };

    if (previousLanguage !== i18n.language && previousLanguage !== "init") {
      setPreviousLanguage(i18n.language);
      getData();
    }
  }, [i18n.language]);
  console.log(data);

  if (data === null) {
    return <Loader />;
  }

  if (status === "failed") {
    return <div>Error: {error}</div>;
  }

  let backgroundImageVariable = "";

  if (data.sliders && data.sliders.bg_media_type === "image") {
    backgroundImageVariable = `url(${data.sliders.background})`;
  } else {
    backgroundImageVariable = "none";
  }
  return (
    <div className={styles.mainContainer}>
      <div
        className={styles.videoBackground}
        style={{
          position: "relative",
          backgroundImage: backgroundImageVariable,
        }}
      >
        <Navbar />
        {data.sliders && (
          <section className={styles.heroContainer}>
            <img
              className={styles.logo}
              src={data.sliders.logo}
              alt="yc-global"
              height={90}
            />
            <h1
              className={styles.heroTitle}
              style={{
                maxWidth: "75vw",
                textAlign: "center",
                color: `${data.sliders.title_color}`,
              }}
            >
              {data.sliders.title}
            </h1>
          </section>
        )}
        {data.sliders && data.sliders.bg_media_type === "video" && (
          <video
            className={styles.video}
            autoPlay
            loop
            muted
            controls={false}
            webkit-playsinline
            playsInline
          >
            <source src={data.sliders.background} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div>

      {data.news && data.news.length > 0 && (
        <section className={styles.latestNews}>
          <h3 style={{ alignSelf: "center" }}>{t("home-latestnew")}</h3>
          <div className={styles.gridContainer}>
            {data.news &&
              data.news.map((item: any, index: number) => {
                return (
                  <div key={index} className={styles.newsItem}>
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "15px",
                      }}
                      src={item.image}
                      alt={`${index}`}
                    />
                    <div className={styles.maskContainer}>
                      <p className={styles.newsTitle}>{item.title}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        </section>
      )}

      {data.visions && data.visions.length > 0 && (
        <section className={styles.missionContainer}>
          <div className={styles.missionContent}>
            {data.visions &&
              data.visions.map((item: any, index: number) => (
                <div
                  key={index}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <h3 className={styles.missionTitle}>{item.title}</h3>
                  <p className={styles.missionDescription}>
                    {item.description}
                  </p>
                </div>
              ))}
          </div>
        </section>
      )}

      {data.companyProfiles && (
        <>
          {screenWidth < 1081 ? (
            <section className={styles.companyProfile}>
              <div className={styles.companyProfileHeader}>
                <h3 style={{ textAlign: "center" }}>
                  {data.companyProfiles && data.companyProfiles.title}
                </h3>
                <p
                  style={{
                    lineHeight: "26px",
                    fontWeight: "400",
                    textAlign: "left",
                  }}
                >
                  {data.companyProfiles && data.companyProfiles.description}
                </p>
              </div>
              {data.descriptionProfiles &&
                data.descriptionProfiles.map((item: any, index: number) => {
                  return (
                    <div key={index} className={styles.profileItem}>
                      <img
                        className={styles.profileItemImage}
                        src={item.image}
                      />
                      <h3 className={styles.profileDetailsTitle}>
                        {item.title}
                      </h3>
                      <p
                        className={styles.profileDetailsDescription}
                        style={{
                          textAlign: item.align,
                        }}
                      >
                        {item.description}
                      </p>
                    </div>
                  );
                })}
            </section>
          ) : (
            <section className={styles.companyProfile}>
              <h3 style={{ textAlign: "center" }}>
                {" "}
                {data.companyProfiles && data.companyProfiles.title}
              </h3>
              <p
                style={{
                  lineHeight: "26px",
                  fontSize: "18px",
                  fontWeight: "400",
                }}
              >
                {data.companyProfiles && data.companyProfiles.description}
              </p>

              <div className={styles.profileList}>
                {data.descriptionProfiles &&
                  data.descriptionProfiles.map((item: any, index: number) => {
                    return (
                      <div
                        key={index}
                        className={styles.profileItem}
                        style={{
                          flexDirection: index % 2 ? "row-reverse" : "row",
                        }}
                      >
                        <div className={styles.profileItemImageContainer}>
                          <img
                            className={styles.profileItemImage}
                            src={item.image}
                          />
                        </div>

                        <div className={styles.profileItemDetailsContainer}>
                          <h3 className={styles.profileDetailsTitle}>
                            {item.title}
                          </h3>
                          <p
                            className={styles.profileDetailsDescription}
                            style={{ textAlign: item.align }}
                          >
                            {item.description}
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </section>
          )}
        </>
      )}

      {data.history && data.history.length > 0 && (
        <section className={styles.ourHistory}>
          <h3>{t("home-history-title")}</h3>
          <Swiper
            modules={[Pagination, A11y]}
            spaceBetween={12}
            slidesPerView={numberOfSlides}
            pagination={{ clickable: true }}
          >
            {data.histories &&
              data.histories.map((item: any, index: number) => {
                return (
                  <SwiperSlide>
                    <HistoryCard item={item} />
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </section>
      )}

      <Footer data={data.footers} />
    </div>
  );
};

export default Home;
