import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../api/config/apiConstant"

// interface Sliders {
//   title: string;
//   description: string;
//   logo: string;
//   bg_media_type: string;
//   background: string;
// }

// interface NewsItem {
//   image: string;
//   title: string;
// }

// interface Vision {
//   title: string;
//   description: string;
//   sequence: number;
// }

// interface CompanyProfile {
//   title: string;
//   description: string;
//   sequence: number;
// }

// interface DescriptionProfile {
//   title: string;
//   description: string;
//   image: string;
//   sequence: number;
//   reference_id: number;
// }

interface HomeState {
  sliders: any;
  news: any;
  visions: any;
  companyProfiles: any;
  descriptionProfiles: any;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: HomeState = {
  sliders: [],
  news: [],
  visions: [],
  companyProfiles: [],
  descriptionProfiles: [],
  status: "idle",
  error: null,
};

export const fetchHomeContent = createAsyncThunk<
  HomeState,
  string,
  { rejectValue: string }
>("home/fetchContent", async (language: string, { rejectWithValue }) => {
  try {
    const response = await axios.get<HomeState>(API_URL + "/homepage", {
      headers: { language: language },
    });
    return response.data;
  } catch (error) {
    return rejectWithValue((error as Error).message);
  }
});

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHomeContent.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchHomeContent.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.status = "succeeded";
          state.sliders = action.payload.data.sliders;
          state.news = action.payload.data.news;
          state.visions = action.payload.data.visions;
          state.companyProfiles = action.payload.data.companyProfiles;
          state.descriptionProfiles = action.payload.data.descriptionProfiles;
        }
      )
      .addCase(fetchHomeContent.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload ?? "Unknown error occurred";
      });
  },
});

export default homeSlice.reducer;
