import React from "react";
import { Link } from "react-router-dom";
import styles from "./historyCard.module.css";
import { useTranslation } from "react-i18next";

type ItemProps = {
  item: {
    sequence: number;
    image: string;
    title: string;
    description: string;
  };
};

const HistoryCard: React.FC<ItemProps> = ({ item }: ItemProps) => {

  return (
    <div key={item.sequence} className={styles.historyCard}>
      <img
        style={{ borderRadius: "15px" }}
        height={250}
        width={405}
        src={item.image}
      />
      <p>{item.title}</p>
    </div>
  );
};

export default HistoryCard;
