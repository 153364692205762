import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./navbar.module.css";
import logoCompany from "../../assets/yc global 2.png";
import { ReactComponent as LanguageIcon } from "../../assets/lg-vector.svg";

const Navbar: React.FC = () => {
  const { t, i18n } = useTranslation();

  const [isLgSelectorOpen, setLgSelectorOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
    setLgSelectorOpen(false);
  }, [selectedLanguage]);

  const selectLanguage = (language: string) => {
    setSelectedLanguage(language);
  };

  const openLanguageSelector = () => {
    setLgSelectorOpen(!isLgSelectorOpen);
  };

  return (
    <div className={styles.navbar}>
      <img className={styles.logo} src={logoCompany} />
      <div className={styles.navRight}>
        {/* <nav>
               <ul>
                  <li><Link to="/">{t("nav-about-us")}</Link></li>
                  <li><Link to="/about">{t("nav-our-features")}</Link></li>
                  <li><Link to="/about">{t("nav-new")}</Link></li>
                  <li><Link to="/about">{t("nav-join-us")}</Link></li>
               </ul>
            </nav> */}
        <div
          className={styles.languageSelector}
          onClick={() => openLanguageSelector()}
        >
          <div className={styles.languageSelectorLabelContainer}>
            <LanguageIcon />
            <div className={styles.languageSelectorLabel}>{t(`${selectedLanguage}`)}</div>
          </div>

          {isLgSelectorOpen === true && (
            <div className={styles.languageSelectorOptionlist}>
              <div
                className={styles.languageSelectorOption}
                onClick={() => selectLanguage("en")}
              >
                {t(`en`)}
              </div>
              <div
                className={styles.languageSelectorOption}
                onClick={() => selectLanguage("zh")}
              >
                {t(`zh`)}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
