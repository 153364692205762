// src/i18n.ts
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translation files
import en from '../locales/en/translation.json';
import zh from '../locales/zh/translation.json';

// Define the resources type as a Record<string, any>
const resources = {
  en: {
    translation: en,
  },
  zh: {
    translation: zh,
  },
};

// Initialize i18n
i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en', // default language
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
  });

export default i18n;
