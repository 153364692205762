import axios, { AxiosResponse } from "axios";

// Define base URL for API (adjust according to your API server)
const API_BASE_URL = "https://admin.ycinvest.com/api";

// Create an axios instance to configure default options (optional)
const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`, // Modify as necessary
  },
});

// Example: Reusable API calls for a user profile page

// Fetch User Details
export const fetchHomeDetails = async (language: string): Promise<any> => {
  const response: AxiosResponse<any> = await apiClient.get(`/homepage`, {
    headers: { language: language },
  });
  return response.data;
};
