import React from "react";
import Navbar from "../components/navbar/navbar";
import Footer from "../components/footer/footer";

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div style={{ minHeight: "100vh", minWidth: "100vw" }}>
      <main style={{ display: "flex", justifyContent: "center", padding: 0 }}>
        {children}
      </main>
    </div>
  );
};

export default Layout;
