import React from "react";
import { Link } from "react-router-dom";
import styles from "./footer.module.css";
import logoCompany from "../../assets/yc global 2.png";
import { useTranslation } from "react-i18next";

type FooterProps = {
  data: any;
};

const Footer: React.FC<FooterProps> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.footer}>
      <div className={styles.footerTop}>
        <div>
          <img className={styles.logo} src={logoCompany} />
          <div className={styles.footerDataWrapper}>
            {data &&
              data.length > 0 &&
              data.map((item: any, index: number) => (
                <div className={styles.footerData}>
                  <span className={styles.footerDataTitle}>{item.title}</span>
                  <span className={styles.footerDataDivider}>{item.title === "" ? "" : ": "}</span>
                  <span className={styles.footerDataDescription}>
                     {item.description}
                  </span>
                </div>
              ))}
          </div>
        </div>

        {/* <nav>
               <ul>
                  <li><Link to="/">{t('footer-about-us')}</Link></li>
                  <li><Link to="/about">{t('footer-our-features')}</Link></li>
                  <li><Link to="/about">{t('footer-new')}</Link></li>
                  <li><Link to="/about">{t('footer-join-us')}</Link></li>
               </ul>
            </nav> */}
      </div>
      <div className={styles.divider} />
      <div className={styles.footerBottom}>
        <span>{t("footer-copyright")}</span>

        {/* <nav>
               <ul>
                  <li><Link to="/">{t('footer-termscondition')}</Link></li>
                  <li><Link to="/about">{t('footer-privacy')}</Link></li>

               </ul>
            </nav> */}
      </div>
    </div>
  );
};

export default Footer;
